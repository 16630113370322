import React from "react";
import backgroundFooter from "../../assets/img/Background4_Web.png";
// import backgroundFooter_mob from "../../assets/img/Background3_Mob.png";

interface IProps { }
export const Footer = (props: IProps) => {
  // const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);
  // window.addEventListener("resize", (e: any) => setScreenWidth(e.target.innerWidth));

  return (
    <div style={{ display: "flex", width: '100%' }}>
      {/* {screenWidth > 700 ? ( */}
      <img
        src={backgroundFooter}
        className={"jss_27"}
        alt='Footer'
      />
      {/* ) : (
        <img src={backgroundFooter_mob} className={"jss_12"} alt='Footer' />
      )} */}
    </div>
  );
};
