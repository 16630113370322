import React from "react";
import FormTitle from "../../assets/img/formTitle.png";
// import { Banner, Banner_Mob, Footer } from "../../components";
import { FormContent, FormContent_English } from "./_";
import { Grid, Typography } from "@material-ui/core";

interface IProps {
  page: Page;
  setPage: any;
  country: Country;
  setCountry: any;
  language: Language;
}
export const SubmitForm = (props: IProps) => {
  // const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);
  // window.addEventListener("resize", (e: any) => setScreenWidth(e.target.innerWidth));

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div className='Page'>
      {/* <div style={{ height: "100%" }}>
        {screenWidth > 700 ? (
          <Banner language={props.language} />
        ) : (
          <Banner_Mob language={props.language} />
        )}
      </div> */}
      <Grid className='jss_22' justifyContent='center' container direction={"column"}>
        <div className='jss_43'>
          <img src={FormTitle} className='img5 ' alt='Image5' />
          <div className='jss_44'>
            {props.language === "Arabic" ? (
              <Typography variant='h1'>أدخل التفاصيل للفوز</Typography>
            ) : (
              <Typography variant='h1'>Enter details to win</Typography>
            )}
          </div>
        </div>
        {props.language === "Arabic" ? (
          <FormContent
            country={props.country}
            setCountry={props.setCountry}
            page={props.page}
            setPage={props.setPage}
          />
        ) : (
          <FormContent_English
            country={props.country}
            setCountry={props.setCountry}
            page={props.page}
            setPage={props.setPage}
          />
        )}
      </Grid>
    </div>
  );
};
