import { Select, MenuItem } from "@material-ui/core";
import React from "react";
interface IProps {
  language: Language;
  setLanguage: any;
}
export const LanguageButton = (props: IProps) => {
  return (
    <Select
      style={{ height: "100%" }}
      value={props.language}
      onChange={e => props.setLanguage(e.target.value as string)}
    >
      <MenuItem value={"Arabic"}>Arabic</MenuItem>
      <MenuItem value={"English"}>English</MenuItem>
    </Select>
  );
};
