import React from "react";
import backgroundBanner from "../../assets/img/bg_1_web_arb.png";
import backgroundBannerEng from "../../assets/img/bg_1_web_eng.png";

// import Enter_now from "../../assets/img/Enter_now_home_1.png";
// import backgroundBanner_eng from "../../assets/img/Background-home-1_1_eng.png";
// import Enter_now_eng from "../../assets/img/Enter_now_home_1_eng.png";
interface IProps {
  handleButtonClick?: any;
  language: Language;
}
export const Banner = (props: IProps) => {
  return (
    <div className='jss_1 jss_2 jss_9'>
      <img
        src={props.language === 'English' ? backgroundBannerEng : backgroundBanner}
        className='img5'
        alt='Image2'
      />
    </div>
  );
};
