import { createStyles, createTheme, ThemeOptions } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});
export const appTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#86131E",
      light: "#ffffff",
      dark: "#080c11",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#CA4141",
      dark: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "DiodrumArabic-Bold",
    h1: {
      fontFamily: "DiodrumArabic-Bold",
      [breakpoints.down("md")]: {
        fontSize: "5vmin",
      },
      [breakpoints.up("md")]: {
        fontSize: "2rem",
      },
      fontWeight: 900,
      color: "#FFFFFF",
    },
    h2: {
      [breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
      color: "#FFFFFF",
      fontFamily: "DiodrumArabic-Bold",
      fontWeight: 900,
    },
    h3: {
      color: "#FFFFFF",
      [breakpoints.down("md")]: {
        fontSize: "0.5rem",
      },
      [breakpoints.up("md")]: {
        fontSize: "0.75rem",
      },
      fontFamily: "DiodrumArabic-Bold",
      fontWeight: 900,
    },
    h4: {
      [breakpoints.down("sm")]: {
        fontSize: "0.5rem",
      },
      [breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
      fontFamily: "DiodrumArabic-Bold",
      fontWeight: 900,
      color: "#CA4141",
    },

    h6: {},
  },
  breakpoints: {},
  overrides: {
    MuiFormLabel: createStyles({
      root: {
        fontFamily: "DiodrumArabic-Bold",
        fontWeight: 900,
        color: "#CFCFCF",
        "&.Mui-focused": {
          color: "#000000",
        },
      },
      asterisk: {
        color: "#FF4949",
        fontFamily: "DiodrumArabic-Bold",
        fontWeight: 900,
      },
    }),

    MuiFilledInput: createStyles({
      root: {
        backgroundColor: "#eaf2fb",
        borderRadius: "5px 5px 5px 5px",
        borderTopLeftRadius: "none",
        borderTopRightRadius: "none",
        textAlign: "start",
        "&:hover": {
          backgroundColor: "#eaf2fb",
        },
        "&.Mui-focused": {
          backgroundColor: "#eaf2fb",
          textAlign: "start",
        },
      },
      underline: {
        "&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    }),
    MuiInput: createStyles({
      root: {
        backgroundColor: "#eaf2fb",
        borderRadius: "5px 5px 5px 5px",
        borderTopLeftRadius: "none",
        borderTopRightRadius: "none",
        padding: "1% 2%",
      },
      underline: {
        "&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
        "&&:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
      },
      // "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      //   display: "none",
      // },
      // "& input[type=number]": {
      //   MozAppearance: "textfield",
      // },
    }),
    MuiButton: createStyles({
      root: {
        borderRadius: "15px 15px 15px 15px",
        [breakpoints.down("sm")]: {
          padding: "10px 10px",
          minWidth: "100%",
        },
      },
      containedPrimary: {
        "&:hover": {
          // @ts-ignore
          backgroundColor: "#86131E",
          opacity: 0.8,
        },
      },
      label: {
        // marginBottom: "3%",
      },
    }),

    MuiCheckbox: createStyles({
      root: {
        color: "#CFCFCF",
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: "#0176ff",
        },
      },
    }),
    MuiInputLabel: createStyles({
      formControl: {
        [breakpoints.down("sm")]: {
          fontSize: "2vmin",
        },
        [breakpoints.up("sm")]: {
          fontSize: "1rem",
        },
        left: "auto",
        right: 0,
        marginRight: "20px",
      },
    }),
    MuiInputBase: createStyles({
      root: {
        [breakpoints.down("sm")]: {
          fontSize: "2vmin",
        },
        [breakpoints.up("sm")]: {
          fontSize: "1rem",
        },
        fontFamily: "DiodrumArabic-Bold",
        fontWeight: 900,
      },
    }),
    MuiSelect: createStyles({
      iconFilled: {
        right: "auto",
        left: "7px",
      },
      icon: {
        color: "#CA4141",
      },
    }),
    MuiFormControlLabel: createStyles({
      root: {
        marginRight: "0px",
      },
    }),
    MuiListItem: createStyles({
      root: {
        justifyContent: "flex-end",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
    }),
    MuiCircularProgress: createStyles({
      colorPrimary: {
        color: "#FFFFFF",
      },
    }),
  },
});
