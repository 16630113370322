import * as yup from "yup";
export const validationSchema = yup.object({
  fullName: yup.string().required("Full Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  countryCode: yup.string().required("Country Code is required"),
  phoneNumber: yup.number().required("Phone Number is required"),
  city: yup.string().required("City is required"),
  retailer: yup.string().required("Retailer is required"),
  // retailer_user: yup.string().required("Retailer is required"),
  purchase_receipt: yup.mixed().required("Purchase Receipt is required"),
  t_c: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

export const retailer_uae_eng = [
  "Retailer",
  "Carrefour",
  "Lulu",
  "Union Coop",
  "Ajman Coop",
  "ADH Coop",
  "Sharjah Coop",
  " Spinneys",
  "Choithrams",
  "Al Maya",
  " Emirates Coop",
  "Al Ain Coop",
  "Nesto",
  "Other",
];
export const retailer_uae_arb = [
  "بائع تجزئة",
  "Carrefour",
  "Lulu",
  "Union Coop",
  "Ajman Coop",
  "ADH Coop",
  "Sharjah Coop",
  " Spinneys",
  "Choithrams",
  "Al Maya",
  " Emirates Coop",
  "Al Ain Coop",
  "Nesto",
  "Other",
];
export const retailer_sa_eng = [
  "Panda",
  " Othaim",
  "Danube",
  "Tamimi",
  "Carrefour",
  "Bindawood",
  "Farm",
  "Al Raya",
  "Al Sadhan",
  "Lulu",
  "Manuel",
  "Other",
];
export const retailer_sa_arb = [
  "باندا",
  "العثيم",
  "دانوب",
  "التميمي",
  "كارفور",
  "بن داوود",
  "فارم",
  "الرايا",
  "السدحان",
  "لولو",
  "مانويل",
  "غيرها",
];

export const cities_sa_eng = [
  "Abha",
  "Al Abqaiq",
  "Al Bahah",
  "Al Buraidah",
  "Al Dammam ",
  "Al Dhahran",
  "Al Hofuf ",
  "Al Ha'il ",
  "Hafar Al-Batin",
  "Al Jazan",
  "Al Jawf",
  "Jeddah",
  "Jubail",
  "Khamis Mushait",
  "Al Kharj ",
  "Al King Khalid Military City",
  "Al Khobar",
  "Mecca",
  "Al Medina",
  "Al Najran ",
  "Al Qatif",
  "Al Qunfudhah",
  "Ras Tanura",
  "Al Riyadh",
  "Al Sakakah",
  "Al Tabuk",
  "Al Ta'if",
  "Yanbu",
  "Other",
];
export const cities_sa_arb = [
  "البقيق",
  "الباحة",
  "البريدة",
  "الدمام",
  "الظهران",
  "الهفوف",
  "الحائل",
  "حفر الباطن",
  "الجازان",
  "الجوف",
  "جدة",
  "الجبيل",
  "الخميس مشيط",
  "الخرج",
  "مدينة الملك خالد العسكرية",
  "الخبر",
  "مكة",
  "المدينة المنورة",
  "النجران",
  "القطيف",
  "القنفذة",
  "رأس تنورة",
  "الرياض",
  "سَكَاكَا",
  "التبوك",
  "الطائف",
  "ينبع",
  "آخر غيرها",
];

export const cities_uae_eng = [
  "Dubai",
  "Sharjah",
  "Ajman",
  "Abu Dhabi",
  "Al Ain",
  "Fujairah",
  "Ras Al Khaimah",
  "Umm Al Quwain",
];
export const cities_uae_arb = [
  "دبي",
  "الشارقة",
  "عجمان",
  "أبو ظبي",
  "العين",
  "الفجيرة",
  "رأس الخيمة",
  "أم القيوين",
];
